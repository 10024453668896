import React from 'react';
import { Carousel } from 'react-bootstrap';

const imageSize = {
  maxWidth: '100%',
  height: 'auto',
};

const eventContentStyle = {
  color: 'white',
  padding: '20px',
  borderRadius: '8px',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
};

export default function Events() {
  return (
    <>
      {/* Event 1 */}
      <div className="mx-5 my-4">
        <div className="row">
          {/* Left side image */}
          <div className="col-md-6 p-3">
            <img
              className="d-block w-100"
              src="assets/event7/1 (3).jpg"
              alt="रक्तदान शिविर - 2024"
              style={imageSize}
              loading="lazy" // Lazy loading enabled
            />
          </div>
          {/* Right side content */}
          <div className="col-md-6 p-5 bg-success" style={eventContentStyle}>
            <h2>रक्तदान शिविर - 2024</h2>
            <p>
              मई 2024 में, हमारे प्रदेश में एक और महत्वपूर्ण रक्तदान शिविर का सफल आयोजन किया गया, जिसमें समाज के कई लोगों ने बढ़-चढ़कर भाग लिया। इस शिविर में स्वास्थ्य सेवा के प्रति समर्पण और सामुदायिक समर्थन की भावना को प्रकट किया गया। 
              <br /><br />
              इस वर्ष, रक्तदान शिविर के माध्यम से सैकड़ों यूनिट रक्त एकत्र किया गया, जिससे कई ज़िंदगियों को बचाने में सहायता मिली। इस महत्वपूर्ण योगदान के लिए सभी रक्तदाताओं का हार्दिक धन्यवाद किया गया।
              <br /><br />
              रक्तदान के महत्व को समझते हुए, यह आयोजन समाज में स्वास्थ्य के प्रति जागरूकता फैलाने का एक महत्वपूर्ण माध्यम बना। यह शिविर सामुदायिक सेवा और मानवता की भावना को बढ़ावा देने का प्रतीक है, और हम सभी को अपने कर्तव्यों के प्रति प्रेरित करता है।
            </p>
          </div>
        </div>
      </div>

      {/* Event 2 */}
      <div className="mx-5 my-4">
        <div className="row">
          {/* Left side content */}
          <div className="col-md-6 p-5 bg-success" style={eventContentStyle}>
            <h2>वृक्षारोपण - 2024</h2>
            <p>
              मार्च 2024 में, हमने एक भव्य वृक्षारोपण समारोह का आयोजन किया, जिसका उद्देश्य हमारे प्रदेश की हरियाली और पर्यावरण संरक्षण को बढ़ावा देना था। इस आयोजन में प्रदेश के लोगों, विशेषकर युवाओं और बच्चों की बड़ी भागीदारी रही। हजारों नए पौधे लगाए गए, जिससे न केवल प्राकृतिक सौंदर्य में वृद्धि हुई, बल्कि पर्यावरण संरक्षण के प्रति जागरूकता भी फैली। वृक्षों की देखभाल और उनके महत्व को समझाने के लिए विभिन्न गतिविधियाँ भी आयोजित की गईं।
              <br /><br />
              इस वृक्षारोपण समारोह के माध्यम से हमने स्वच्छ और हरित भविष्य की दिशा में एक और महत्वपूर्ण कदम उठाया। सभी सहभागियों ने पर्यावरण सुरक्षा का संकल्प लिया और वृक्षों की रक्षा करने की प्रतिबद्धता जताई। यह आयोजन हमारे समुदाय के सतत विकास और पर्यावरण संरक्षण के प्रति हमारी प्रतिबद्धता का प्रतीक है, जिसमें हमने एक साथ मिलकर हरित और स्वच्छ भविष्य की दिशा में कदम बढ़ाया।
            </p>
          </div>
          {/* Right side image */}
          <div className="col-md-6 p-3">
            <img
              className="d-block w-100"
              src="assets/event6/1 (13).jpg"
              alt="वृक्षारोपण - 2024"
              style={imageSize}
              loading="lazy" // Lazy loading enabled
            />
          </div>
        </div>
      </div>

      {/* Event 3 */}
      <div className="mx-5 my-4">
        <div className="row">
          {/* Left side image */}
          <div className="col-md-6 p-3">
            <img
              className="d-block w-100"
              src="assets/event4/1 (3).jpg"
              alt="मकरेणी कौथिक महोत्सव"
              style={imageSize}
              loading="lazy" // Lazy loading enabled
            />
          </div>
          {/* Right side content */}
          <div className="col-md-6 p-5 bg-success" style={eventContentStyle}>
            <h2>मकरेणी कौथिक महोत्सव (जनवरी, 2024)</h2>
            <p>
              उत्तराखंड सांस्कृतिक एकता मंच ने गुरुग्राम में आयोजित हुए मकरेणी कौथिक महोत्सव के सुखद पलों को साझा करते हुए समारोह को सम्पन्न किया। यह समारोह उत्तराखंड के समृद्ध सांस्कृतिक विरासत को बढ़ावा देने में अद्वितीय योगदान दिया और स्थानीय जन समाज को एक साथ लाने में सफल रहा।
              <br /><br />
              मकरेणी कौथिक महोत्सव में मुख्य अतिथियों के रूप में राकेश दौलताबाद और जी एल शर्मा ने समारोह को विशेष रूप से सजग किया। इसके साथ ही, चंद्र दत्त जोशी और मदन मोहन बिष्ट ने छात्रों को सम्मानित करने में भागीदार रहे।
              <br /><br />
              लोक गायिका हेमा नेगी, मुकेश कठैत, मनोज आर्य, और जगमोहन रावत चौंदकोटी जी ने अपने सुरों से सभी को मोहित किया, जबकि हास्य कलाकार संदीप छिलबट ने समारोह में हंसी की धारा बहाई।
              <br /><br />
              इस समारोह ने लोगों को मिलकर मनाने का सुंदर मौका प्रदान किया और स्थानीय उत्तराखंड की सांस्कृतिक धरोहर को और भी मजबूती से महसूस कराया।
            </p>
          </div>
        </div>
      </div>

      {/* Event 4 */}
      <div className="mx-5 my-4">
        <div className="row">
          {/* Left side content */}
          <div className="col-md-6 p-5 bg-success" style={eventContentStyle}>
            <h2>होली मिलन समारोह (मार्च, 2023)</h2>
            <p>
              हमारे प्यारे उत्तराखंड समुदाय ने इस होली पर एक आदर्श और अद्वितीय समारोह का आयोजन किया, जिसमें हम ने न केवल रंगों का खेल किया, बल्कि हमारे साथी उत्तराखंडी भाइयों और बहनों के साथ गुड़गांव के एक साथी मानव-समूह में एकत्र होकर आनंद उच्छाटने का भी मौका मिला।
              <br /><br />
              हमारे समुदाय के इस समारोह में फ़ोल्क डांसेस की ख़ास धमाल होती है, जो हमारी रिच उत्तराखंडी संस्कृति को दर्शाते हैं। गर्मियों के आगमन के साथ ही, हमने बावर, चोपड़, जागर, फ़िल्मी और अन्य पारंपरिक डांसेस के साथ-साथ बच्चों के खिलौने और ख़ास खाने-पीने की भी व्यवस्था की। 
              <br /><br />
              समारोह के दौरान, हमने साथ में बिताए गए समय का आनंद उठाया, और फ़ोल्क डांसेस की झलकियों के साथ हमारी संस्कृति को स्वीकारा। यह समारोह हमें अपनी समुदाय की शक्ति और सामूहिकता का अहसास कराता है, और हम सभी मिलकर एक सजीव और आनंदमय उत्तराखंडी भविष्य की दिशा में कदम बढ़ाते हैं।
            </p>
          </div>
          {/* Right side carousel */}
          <div className="col-md-6 p-3">
            <Carousel>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src="assets/event2/1 (2).jpg"
                  alt="Slide 1"
                  loading="lazy" // Lazy loading enabled
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src="assets/event2/1 (3).jpg"
                  alt="Slide 2"
                  loading="lazy" // Lazy loading enabled
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src="assets/event2/1 (4).jpg"
                  alt="Slide 3"
                  loading="lazy" // Lazy loading enabled
                />
              </Carousel.Item>
            </Carousel>
          </div>
        </div>
      </div>

      {/* Event 5 */}
      <div className="mx-5 my-4">
        <div className="row">
          {/* Left side content */}
          <div className="col-md-6 p-5 bg-success" style={eventContentStyle}>
            <h2>रक्तदान शिविर (80 यूनिट)</h2>
            <p>
              मई के 28 तारीख को हमारे प्रदेश के एक अद्वितीय रक्तदान शिविर का आयोजन किया गया था, जिसमें हमने समाज के लिए महत्वपूर्ण रक्तदान के माध्यम से नागरिकों के स्वास्थ्य का समर्पण किया। यह आयोजन न केवल विभागीय स्वास्थ्य सेवाओं के लिए एक महत्वपूर्ण योगदान था, बल्कि यह एक सामुदायिक दृष्टिकोण भी रखता है जो हमारे समुदाय के अद्वितीय आदर्शों को उजागर करता है।
              <br /><br />
              रक्तदान के इस अभियान ने स्थानीय नागरिकों में सहयोग और सेवा का विचार प्रकट किया, जो हमारे सभी रक्तदाताओं के समर्पण का प्रतीक है। इस शिविर में 80 यूनिट रक्त एकत्र किया गया, जिससे कई जीवन बचाने में मदद मिली। 
              <br /><br />
              इस सफल अभियान के लिए सभी सहयोगियों और रक्तदाताओं का दिल से धन्यवाद करते हैं। यह घटना हमें समाज के प्रति हमारी जिम्मेदारियों का पुनः याद दिलाती है, और हमें आगे आने वाले समय में ऐसे आयोजनों को बढ़ावा देने की प्रेरणा देती है।
            </p>
          </div>
          {/* Right side image */}
          <div className="col-md-6 p-3">
            <img
              src="assets/images/1 (11).jpg"
              alt="Blood Donation Camp"
              style={imageSize}
              loading="lazy" // Lazy loading enabled
            />
          </div>
        </div>
      </div>
    </>
  );
}
