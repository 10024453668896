import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import HistoryRecap from './HistoryRecap';

export default function About() {
  return (
    <div id="about" className="container-fluid">
      <div id="abt" className='aboutSlide'></div>

      <div className="mt-4 mx-2">
        <div className="row">
          <div className="col-12 col-md-8">
            <h1 className="h2">हमारे बारे में</h1>
            <p className="lead fw-normal pr-5">
              उत्तराखंड सांस्कृतिक एकता मंच (पंजीकृत 2947) प्रवासी उत्तराखंडवासियों द्वारा स्थापित एक सामाजिक एवं सांस्कृतिक संगठन है।
            </p>
            <p>
              इसमें समाज एवं मेहनतकश लोगों की सहमति से 2017 में इस सभा का गठन किया गया, जो समाज के विभिन्न क्षेत्रों के असीम प्रेम, सहयोग हेतु किया गया।
              अथक प्रयास और मेहनत का जीता जागता उदाहरण, अनेकता में एकता को दर्शाता है।
            </p>
            <p>
              इस संस्था को सुचारू और सुव्यवस्थित तरीके से चलाने के लिए, निम्नलिखित निकाय सदस्यों का गठन किया गया और सोसायटी कानून के अनुसार 3 साल की अवधि के लिए चुना गया,
              पहले निर्वाचित निकाय सदस्यों को महामारी COVID19 के कारण 6 साल के लिए बढ़ा दिया गया था।
            </p>
            <p>
              शुरुआत में निर्वाचित सदस्यों और उत्तराखंड निवासियों के साथ हर साल माता का जागरण आयोजित किया जाता था,
              साथ ही रक्तदान शिविर, स्वच्छता अभियान, चिकित्सा शिविर जैसी सामाजिक गतिविधियों के साथ जरूरतमंदों को आर्थिक और सामाजिक रूप से मदद की जाती थी।
            </p>
            <p>
              संगठन के कार्यप्रवाह को जारी रखने के लिए फरवरी 2023 में नए निकाय सदस्यों का चुनाव किया गया, जिन्होंने 6 महीने के भीतर विभिन्न गतिविधियाँ हासिल कीं और कीं।
            </p>
          </div>
          <div className="col-12 col-md-4 bg-success text-white p-2 rounded">
            <h3>हमारे लक्ष्य</h3>
            <div style={{ height: '299px', overflowY: 'scroll' }}>
              <ul className="list-group">
                <li className="list-group-item">उत्तराखण्ड प्रवासी समाज को समुचित ढंग से एकत्रित करना ।</li>
                <li className="list-group-item">भारतीय संविधान के अनुरूप उत्तराखण्डी प्रवासी समाज का विकास । </li>
                <li className="list-group-item">उत्तराखण्ड संस्कृति को बनायें रखना ।</li>
                <li className="list-group-item">संस्था द्वारा विभिन्न संस्थानों एंव समाज सेवियों से परस्पर सम्बन्ध बनाना ।</li>
                <li className="list-group-item">उत्तराखण्डी समाज के ऐसें छात्रों को प्रोत्साहित करना जो विद्यालयी शिक्षा और कला में आगे हों ।</li>
                <li className="list-group-item">सभी प्रवासी भाई-बहिनों के द्वारा आपसी भाईचारे को बढावा देना ।</li>
                <li className="list-group-item">मेधावी छात्रों के लिए प्रतिस्पर्धा कार्यक्रमों का आयोजन करना जिससे उनका बहुमुखी विकास हों ।</li>
                <li className="list-group-item">संस्था का उद्वेश्य सभी प्रवासी भाई-बहिनों के द्वारा आपसी भाईचारे को बढावा देना । </li>
                <li className="list-group-item">उत्तराखण्ड में होने वाली विभिन्न प्राकृतिक आपदाओं में सहायता हेतु अपनी पूर्ण भागीदारी निभाना ।</li>
                <li className="list-group-item">स्वास्थ्य लाभ और शारीरिक उर्जा बनाये रखने के लिए मंच के माध्यम से आयोजन करना ।</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="row mt-3 sky-blue-background p-4">
          <div className="col-12 col-md-6">
            <h3>कार्य समिति सदस्य (2023-26)</h3>
            <p>
              अध्यक्ष : मनोज भंडारी<br />
              उपाध्यक्ष : सुभाष बुड़ाकोटी<br />
              महासचिव : रमिंदर सिंह नेगी<br />
              सचिव : केशव डोबरियाल<br />
              कोषाध्यक्ष : रमेश घिल्डियाल<br />
              सांस्कृतिक सचिव : जग मोहन सिंह रावत<br />
              प्रवक्ता एवं प्रचार सचिव : युद्धवीर बिष्ट<br />
              संगठन सचिव : संदीप रावत, मनमोहन रावत<br />
              प्रचार सचिव एवं अकाउंटेंट : बीरेंद्र रावत<br />
              मीडिया प्रभारी : सुनील रावत<br />
              सलाहकार : मेहरबान सिंह रौथाण, हयात सिंह राणा, शिव प्रसाद बडोनी<br />
              माल सचिव : रवि बर्थवाल<br />
              उपाध्यक्ष (महिला) : हेमलता मिश्रा<br />
              उप सचिव (महिला) : कुसुम जोशी<br />
              प्रचार सचिव (महिला) : विनीता गोसाईं
            </p>
          </div>

          <div className="col-12 col-md-6 sky-blue-background p-4">
            <h3>कार्य समिति सदस्य (2017-23)</h3>
            <p>
              अध्यक्ष : पुष्कर सिंह बिष्ट<br />
              उपाध्यक्ष : सुभाष बुड़ाकोटी<br />
              महासचिव : अनिल प्रकाश मिश्रा<br />
              सचिव एवं प्रवक्ता : मनोज भंडारी<br />
              कोषाध्यक्ष : रमिंदर सिंह नेगी<br />
              सांस्कृतिक सचिव : जग मोहन सिंह रावत<br />
              प्रचार मंत्री : युद्धवीर बिष्ट<br />
              प्रचार सचिव : बीरेंद्र रावत<br />
              संगठन सचिव : गणेश नेगी<br />
              मीडिया प्रभारी : विक्रम बिष्ट<br />
              सलाहकार : देवेन्द्र भट्ट, केशव डोबरियाल
            </p>
          </div>
        </div>

        <div className="contact-info my-4 p-3 border border-secondary rounded">
          <h4>संपर्क करें:</h4>
          <p>
            पंजीकृत कार्यालय: ए/18, लक्ष्मी गार्डन, पुलिस चौकी के पास, खांडसा रोड, गुरुग्राम (हरियाणा) <br />
            मोबाइल: 9999407619, 9958513832
          </p>
        </div>

        <div className="meeting-info my-4 p-3 border border-secondary rounded">
          <h2>संगठन बैठकें और विवरण</h2>
          <p>
            <strong>08.01.2017:</strong> संगठन विकसित करने का विचार आया इसलिए पहली बैठक हुई, उपस्थित सदस्य थे:
          </p>
          <ul>
            <li>1) बृजमोहन नेगी</li>
            <li>2) मनोज भंडारी</li>
            <li>3) पुष्कर सिंह बिष्ट</li>
            <li>4) रमिंदर सिंह नेगी</li>
            <li>5) जगमोहन सिंह रावत</li>
            <li>6) जयदीप सिंह रावत</li>
          </ul>

          <p>
            <strong>22.01.2017:</strong> कमला नेहरू पार्क में 20 सदस्यों के साथ दूसरी बैठक आयोजित की गई और उत्तराखंड की एकता फैलाने और उत्तराखंडी लोगों को इकट्ठा करने का निर्णय लिया।
          </p>

          <p>
            <strong>05.02.2017:</strong> कमला नेहरू पार्क में 40 सदस्यों के साथ तीसरी बैठक आयोजित की गई:
          </p>
          <ul>
            <li>• संगठन की महत्वाकांक्षाएं, संगठन का संविधान श्री मनोज भंडारी द्वारा लिखा गया।</li>
            <li>• संगठन के नाम की घोषणा श्री मनोज भंडारी द्वारा की गई।</li>
          </ul>

          <p>
            <strong>04.02.2018:</strong> इस दिन एक दूसरे के सुझाव एवं सहमति से संस्था को आगे बढ़ाने के लिए 12 सदस्यों का चुनाव किया गया।
          </p>
          <ol>
            <li>पुष्कर सिंह बिष्ट - प्रधान</li>
            <li>सुभाष चंद्र बुड़ाकोटी - उप-प्रधान</li>
            <li>अनिल कुमार मिश्रा - महा सचिव</li>
            <li>मनोज भंडारी - सचिव एवं प्रवक्ता</li>
            <li>रमिंदर सिंह नेगी - कोषादकश</li>
            <li>रमेश चंद्र घिल्डियाल - कोष निरिचक</li>
            <li>बृजमोहन नेगी - सांस्कृतिक मंत्री</li>
            <li>जगमोहन सिंह रावत - उप सांस्कृतिक मंत्री</li>
            <li>युद्धवीर सिंह बिष्ट - प्रचार प्रसार मंत्री</li>
            <li>जयदीप रावत - संगठन मंत्री</li>
            <li>देवेन्द्र प्रसाद भट्ट - सलाहकार</li>
            <li>केशव डोबरियाल - सलाहकार</li>
          </ol>
        </div>
      </div>

      <div className="mt-4 mx-2 bg-cream">
        <HistoryRecap />
      </div>
    </div>
  );
}
